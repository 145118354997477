import React from 'react'
import styles from './index.module.css'

import Container from '../../Container'

import classnames from 'classnames'
import { Grid, Cell } from 'styled-css-grid'
import MediaQuery from 'react-responsive'

import devices from './assets/compoDevicesCTL.png'
import ctl from './assets/centurylink-logo-black-text.png'

import graphBar from './assets/icons/graphBar.svg'
import cible from './assets/icons/Cible.png'
import networks from './assets/icons/Networks.png'

import accenture from './assets/partnership/accenture.svg'
import adobe from './assets/partnership/Adobe.svg'
import comscore from './assets/partnership/comscore.svg'
import nielsen from './assets/partnership/nielsen.svg'
import nokia from './assets/partnership/nokia.svg'
import synaptics from './assets/partnership/Synaptics.svg'
import technicolor from './assets/partnership/technicolor.svg'
import think_Analytics from './assets/partnership/Think_Analytics.svg'

const Ctl = () => {
  return (
    <section className={styles.ctl}>
      <div className={styles.triangle}></div>
      <Container rootStyle={styles.container}>
        {/* <div className={styles.ctlBg}></div> */}
        <div className={styles.isLaptop}>
          <Grid columns={12}>
            <Cell width={9} center middle>
              <h2 className={styles.sectionTitle}>Multi Screens TV App</h2>
            </Cell>
            <Cell width={3} height={2}>
              <div className={styles.services}>
                <div className={styles.service}>
                  <div>
                    <img
                      style={{ height: '44px' }}
                      src={cible}
                      alt="screenTv icon"
                    />
                  </div>
                  Adressable TV
                </div>
                <div className={styles.service}>
                  <div>
                    <img src={graphBar} alt="graphBar icon" />
                  </div>
                  Analytics
                </div>
                <div className={styles.service}>
                  <div>
                    <img
                      style={{ height: '44px' }}
                      src={networks}
                      alt="screenTv icon"
                    />
                  </div>
                  US TV Networks content
                </div>
                <div className={styles.service}>
                  <div className={classnames(styles.icon, styles.earth)}></div>
                  Geolocalisation
                </div>
              </div>
            </Cell>

            <Cell width={9}>
              <figure style={{ position: 'relative' }}>
                <div className={styles.ctlBg}></div>
                <img src={devices} alt="multi devices CTL" />
                <img className={styles.logoCtl} src={ctl} alt="Centurylink" />
              </figure>
            </Cell>
          </Grid>
        </div>

        <div className={styles.isTablet}>
          <Grid columns={12}>
            <Cell width={12} center middle>
              <h2 className={styles.sectionTitle}>Multi Screens TV App</h2>
            </Cell>
            <Cell width={12}>
              <figure style={{ position: 'relative' }}>
                <div className={styles.ctlBg}></div>
                <img src={devices} alt="multi devices CTL" />
                <img className={styles.logoCtl} src={ctl} alt="Centurylink" />
              </figure>
            </Cell>
            <Cell width={12} height={2}>
              <div className={styles.services}>
                <div className={styles.service}>
                  <div>
                    <img
                      style={{ height: '44px' }}
                      src={cible}
                      alt="screenTv icon"
                    />
                  </div>
                  Adressable TV
                </div>
                <div className={styles.service}>
                  <div>
                    <img src={graphBar} alt="graphBar icon" />
                  </div>
                  Analytics
                </div>
                <div className={styles.service}>
                  <div>
                    <img
                      style={{ height: '44px' }}
                      src={networks}
                      alt="screenTv icon"
                    />
                  </div>
                  US TV Networks content
                </div>
                <div className={styles.service}>
                  <div className={classnames(styles.icon, styles.earth)}></div>
                  Geolocalisation
                </div>
              </div>
            </Cell>
          </Grid>
        </div>

        <Grid columns={12}>
          <Cell width={12}>
            <h3 className={styles.subTitle}>In partnership with</h3>
          </Cell>
          <Cell width={12} center>
            <div className={styles.partnership}>
              <figure>
                <img src={accenture} alt="accenture" />
              </figure>
              <figure>
                <img src={technicolor} alt="technicolor" />
              </figure>
              <figure>
                <img src={synaptics} alt="synaptics" />
              </figure>
              <figure>
                <img src={nokia} alt="nokia" />
              </figure>
            </div>
          </Cell>
          <Cell width={12} center>
            <div className={styles.partnership}>
              <figure>
                <img src={nielsen} alt="nielsen" />
              </figure>
              <figure>
                <img src={comscore} alt="comscore" />
              </figure>
              <figure>
                <img src={think_Analytics} alt="think_Analytics" />
              </figure>
              <figure>
                <img src={adobe} alt="adobe" />
              </figure>
            </div>
          </Cell>
        </Grid>
      </Container>
    </section>
  )
}

export default Ctl
