import React from 'react'
import classnames from 'classnames'
import { Grid, Cell } from 'styled-css-grid'

import PartnerLogo from './assets/partnerTvBlackWhite.svg'
import MultiDevicePartner from './assets/partnerMultiDevices.png'
import technicolor from './assets/white/technicolor.png'
import synaptics from './assets/white/synaptics.png'
import seaChange from './assets/white/seachange.png'
import broadpeak from './assets/white/broadpeak.png'
import styles from './index.module.css'

// import netflix from './assets/netflix.svg'
import cible from './assets/icons/Cible.png'
import languages from './assets/icons/update - Languages.png'
import tCommerce from './assets/icons/Update - T commerce.png'
import rewind from './assets/icons/rewind.png'

import netflixRed from './assets/netflix.png'
import amazon from './assets/amazon.png'
import spotify from './assets/spotify.png'
import youtube from './assets/youtube.png'

const PartnerTv = () => {
  return (
    <section className={styles.partnerTv}>
      <div className={classnames(styles.corner, styles.topLeft)}></div>

      <header className={styles.header}>
        <div className={styles.isLaptop}>
          <Grid column={12}>
            <Cell width={3}>
              <figure>
                <img
                  className={styles.logo}
                  src={PartnerLogo}
                  alt="Partner Logo"
                />
              </figure>
            </Cell>
            <Cell width={9} center>
              <div>
                <h2>Super Aggregation</h2>
                <div className={styles.aggregators}>
                  <img src={netflixRed} alt="netflix" />
                  <img src={amazon} alt="amazon" />
                  <img src={spotify} alt="spotify" />
                  <img src={youtube} alt="youtube" />
                </div>
              </div>
            </Cell>
          </Grid>
          <Grid columns={12}>
            <Cell width={3}>
              <div className={styles.partnership}>
                <h3>In partnership with</h3>
                <div className={styles.partnershipImages}>
                  <div className={styles.partnershipImage}>
                    <div>
                      {' '}
                      <img src={technicolor} alt="technicolor logo" />
                    </div>
                    <div>
                      {' '}
                      <img src={synaptics} alt="synaptics logo" />
                    </div>
                  </div>
                  <div className={styles.partnershipImage}>
                    <div>
                      {' '}
                      <img src={seaChange} alt="seaChange logo" />
                    </div>
                    <div>
                      {' '}
                      <img src={broadpeak} alt="broadpeak logo" />
                    </div>
                  </div>
                </div>
              </div>
            </Cell>
            <Cell width={9} center middle>
              <div className={styles.speedRow}>
                <div className={classnames(styles.icon, styles.speed)}></div>
                <h3>End to End Deployement in 6 months</h3>
              </div>
            </Cell>
          </Grid>
        </div>

        <div className={styles.isTablet}>
          <div style={{ backgroundColor: '#2CD5C4' }}>
            <Grid column={12}>
              <Cell width={9}>
                <figure>
                  <img
                    className={styles.logo}
                    src={PartnerLogo}
                    alt="Partner Logo"
                  />
                </figure>
              </Cell>
            </Grid>
          </div>

          <Grid columns={12}>
            <Cell width={12}>
              <div className={styles.partnership}>
                <h3>In partnership with</h3>
                <div className={styles.partnershipImages}>
                  <div>
                    <img src={technicolor} alt="technicolor logo" />
                  </div>
                  <div>
                    <img src={synaptics} alt="synaptics logo" />
                  </div>
                  <div>
                    <img src={seaChange} alt="seaChange logo" />
                  </div>
                  <div>
                    <img src={broadpeak} alt="broadpeak logo" />
                  </div>
                </div>
              </div>
            </Cell>
          </Grid>

          <Grid column={12}>
            <Cell width={12} center>
              <div>
                <h2>Super Aggregation</h2>
                <div className={styles.aggregators}>
                  <img src={netflixRed} alt="netflix" />
                  <img src={amazon} alt="amazon" />
                  <img src={spotify} alt="spotify" />
                  <img src={youtube} alt="youtube" />
                </div>
              </div>
            </Cell>
          </Grid>

          <Grid columns={12}>
            <Cell width={12} center middle>
              <div className={styles.speedRow}>
                <div className={classnames(styles.icon, styles.speed)}></div>
                <h3>End to End Deployement in 6 months</h3>
              </div>
            </Cell>
          </Grid>
        </div>
      </header>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div className={styles.multiDevices}>
          <div>
            <img
              className={styles.multiDevicePartner}
              src={MultiDevicePartner}
              alt="multi devices"
            />
          </div>
          <h3>June 2017</h3>
        </div>

        <div className={styles.services}>
          <div className={styles.service}>
            <div className={classnames(styles.icon, styles.live)}></div>
            OTT live TV channels <br />
            Exo player
          </div>

          <div className={styles.service}>
            <img
              style={{ height: '50px', margin: '0 auto 10px', width: '50px' }}
              src={rewind}
              alt="rewind logo"
            />
            Backward EPG <br />
            Replay
          </div>

          <div className={styles.service}>
            <div className={classnames(styles.icon, styles.vod)}></div>
            TVOD / SVOD
          </div>

          <div className={styles.service}>
            <div>
              <img
                style={{ height: '50px', margin: 0 }}
                src={cible}
                alt="cible logo"
              />
            </div>
            Adressable TV
          </div>

          <div className={styles.service}>
            <div className={classnames(styles.icon, styles.startOver)}></div>
            Network timeshifting <br />
            StartOver
          </div>

          <div className={styles.service}>
            <div className={classnames(styles.icon, styles.cloud)}></div>
            Network DVR
          </div>

          <div className={styles.service}>
            <div>
              <img
                style={{ height: '50px', margin: 0 }}
                src={languages}
                alt="languages logo"
              />
            </div>
            UI Multi language
          </div>

          <div className={styles.service}>
            <div>
              <img
                style={{ height: '50px', margin: 0 }}
                src={tCommerce}
                alt="tCommerce logo"
              />
            </div>
            T - Commerce
          </div>
        </div>
      </div>

      <div className={classnames(styles.corner, styles.bottomRight)}></div>
    </section>
  )
}

export default PartnerTv
