import React from 'react'
import uuid from 'uuid/v4'

import arm from './assets/chipset/color/10-arm.png'
import synaptics from './assets/chipset/color/11-Synpatics.png'
import broadcom from './assets/chipset/color/12-Broadcom.png'
import amlogic from './assets/chipset/color/13-Amlogic.png'
import qualcomm from './assets/chipset/color/14-Qualcomm.png'
import intel from './assets/chipset/color/15-Intel.png'
import hisilicon from './assets/chipset/color/16-HiSilicon.png'

const logosArray = [
  {
    logo: arm,
    alt: 'arm',
  },
  {
    logo: synaptics,
    alt: 'synaptics',
  },
  {
    logo: broadcom,
    alt: 'broadcom',
  },
  {
    logo: amlogic,
    alt: 'amlogic',
  },
  {
    logo: qualcomm,
    alt: 'qualcomm',
  },
  {
    logo: intel,
    alt: 'intel',
  },
  {
    logo: hisilicon,
    alt: 'hisilicon',
  },
]

const logos = logosArray.map(logo => {
  return (
    <figure key={uuid()}>
      <img src={logo.logo} alt={logo.alt} />
    </figure>
  )
})

export default logos
