import React from 'react'
import uuid from 'uuid/v4'

import technicolor from './assets/hardware/color/20-Technicolor.png'
import sagemcom from './assets/hardware/color/21-Sagemcom.png'
import seirobotics from './assets/hardware/color/logo_sei_robotics.png'
import arris from './assets/hardware/color/22-Arris.png'
import skyworth from './assets/hardware/color/skyworth.svg'
import kaon from './assets/hardware/color/23-Kaon.png'
import samsung from './assets/hardware/color/24-Samsung.png'
import lg from './assets/hardware/color/25-LG.png'
import arcadyan from './assets/hardware/color/27-Arcadyan.png'
import smartDTV from './assets/hardware/color/smardtv.png'

const logosArray = [
  {
    logo: technicolor,
    alt: 'technicolor',
  },
  {
    logo: sagemcom,
    alt: 'sagemcom',
  },
  {
    logo: seirobotics,
    alt: 'seirobotics',
  },
  {
    logo: arris,
    alt: 'arris',
  },
  {
    logo: skyworth,
    alt: 'skyworth',
  },
  {
    logo: kaon,
    alt: 'kaon',
  },
  {
    logo: samsung,
    alt: 'samsung',
  },
  {
    logo: lg,
    alt: 'lg',
  },
  {
    logo: arcadyan,
    alt: 'arcadyan',
  },
  {
    logo: smartDTV,
    alt: 'smartDTV',
  },
]

const logos = logosArray.map(logo => {
  let partnersLogo = <img src={logo.logo} alt={logo.alt} />
  if (logo.alt === 'seirobotics') {
    partnersLogo = (
      <img src={logo.logo} alt={logo.alt} style={{ height: '80px' }} />
    )
  }
  if (logo.alt === 'skyworth') {
    partnersLogo = (
      <img src={logo.logo} alt={logo.alt} style={{ width: '70%' }} />
    )
  }
  if (logo.alt === 'smartDTV') {
    partnersLogo = (
      <img src={logo.logo} alt={logo.alt} style={{ height: '30px' }} />
    )
  }
  return <figure key={uuid()}>{partnersLogo}</figure>
})

export default logos
