import React from 'react'
import uuid from 'uuid/v4'

import kaltura from './assets/platform/color/33-Kaltura.png'
import viaccessOrca from './assets/platform/color/viaccessOrca-color.png'
import vubiquity from './assets/platform/color/Vubiquity-Logo.png'
import accenture from './assets/platform/color/31-Accenture.png'
import seachange from './assets/platform/color/32-Seachange.png'
import nagra from './assets/platform/color/nagra.jpg'
import comcast from './assets/platform/color/35-Comcast.png'
import broadpeak from './assets/platform/color/37-Broadpeak.png'
import ericsson from './assets/platform/color/38-Ericsson.png'
import huawei from './assets/platform/color/39-Huwai.png'
import nokia from './assets/platform/color/39-2Nokia.png'

const logosArray = [
  { logo: kaltura, alt: 'kaltura' },
  { logo: viaccessOrca, alt: 'viaccessOrca' },
  { logo: vubiquity, alt: 'vubiquity' },
  { logo: accenture, alt: 'accenture' },
  { logo: seachange, alt: 'seachange' },
  { logo: nagra, alt: 'nagra' },
  { logo: comcast, alt: 'comcast' },
  { logo: broadpeak, alt: 'broadpeak' },
  { logo: ericsson, alt: 'ericsson' },
  { logo: huawei, alt: 'huawei' },
  { logo: nokia, alt: 'nokia' },
]

const logos = logosArray.map(logo => {
  let partnersLogo = <img src={logo.logo} alt={logo.alt} />
  if (logo.alt === 'vubiquity') {
    partnersLogo = (
      <img
        src={logo.logo}
        alt={logo.alt}
        style={{ height: 'auto', width: '50%' }}
      />
    )
  }
  if (logo.alt === 'seachange') {
    partnersLogo = (
      <img
        src={logo.logo}
        alt={logo.alt}
        style={{ height: 'auto', width: '70%' }}
      />
    )
  }
  if (logo.alt === 'nagra') {
    partnersLogo = (
      <img
        src={logo.logo}
        alt={logo.alt}
        style={{ height: 'auto', width: '60%' }}
      />
    )
  }
  return <figure key={uuid()}>{partnersLogo}</figure>
})

export default logos
