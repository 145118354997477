import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'

import Bbox from '../components/ReferencesPage/Bbox'
import PartnerTv from '../components/ReferencesPage/PartnerTv'
import Wow from '../components/ReferencesPage/Wow'
import Ctl from '../components/ReferencesPage/Ctl'
import Technical from '../components/ReferencesPage/Technical'

const References = ({ data }) => (
  <Layout>
    <Bbox />
    <PartnerTv />
    <Wow />
    <Ctl />
    <Technical data={data.allStrapiReferencespage.edges[0].node} />
  </Layout>
)

export default References

export const referencePageQuery = graphql`
  query ReferencesQuery {
    allStrapiReferencespage {
      edges {
        node {
          id
          technical_title
          technical_cat_title_1
          technical_cat_images_1
          technical_cat_title_2
          technical_cat_images_2
          technical_cat_title_3
          technical_cat_images_3
          technical_cat_title_4
          technical_cat_images_4
          technical_cat_title_5
          technical_cat_images_5
        }
      }
    }
  }
`
