import React from 'react'
import classnames from 'classnames'
import MediaQuery from 'react-responsive'

import tv from './assets/bboxMiami-Bytel.jpg'

import Bouygues from './assets/bouyguesTelecom.svg'
import BboxMiami from './assets/bboxMiami.png'
import BboxLogo from './assets/bbox_miami.svg'
import Netflix from './assets/netflix.png'

import google from './assets/googleAssistant.svg'
import rewind from './assets/rewind.png'

import synaptics from './assets/Synaptics.svg'
import verimatrix from './assets/verimatrix-gray.png'
import iwedia from './assets/iwedia.svg'
import arcadyan from './assets/Arcadyan.svg'
import spideo from './assets/Spideo.svg'

import styles from './index.module.css'

const Bbox = () => {
  return (
    <section className={styles.bbox}>
      <header className={styles.row} style={{ marginBottom: '1rem' }}>
        <h1>
          More than <span className={styles.highlight}>3M</span> Android TV STB
          deployed in France
        </h1>
        <img
          className={styles.bouygues}
          src={Bouygues}
          alt="logo Bouygues Telecom"
        />
      </header>

      <MediaQuery query="(max-width: 767px)">
        <div className={styles.smallTvWrapper}>
          <img className={styles.smallTv} src={tv} alt="interface Bbox Miami" />
        </div>
      </MediaQuery>
      <div className={styles.reverse}>
        <div className={styles.services}>
          <div className={styles.triangle}></div>
          <div className={styles.serviceWrapper}>
            <div className={styles.service}>
              <div className={classnames(styles.icon, styles.live)}></div>
              IPTV Multicast, OTT <br /> Broadcast DVB-T
            </div>

            <div className={styles.service}>
              <div className={classnames(styles.icon, styles.startOver)}></div>
              Timeshifting / Startover
            </div>

            <div className={styles.service}>
              <div className={classnames(styles.icon, styles.backToLive)}></div>
              Local DVR
            </div>

            <div className={styles.service}>
              {/* <div className={classnames(styles.icon, styles.live)}></div> */}
              <div className={styles.googleAssistant}>
                <img src={google} alt="google assistant logo" />
              </div>
              Google Assistant
            </div>
          </div>
          <div className={styles.serviceWrapper}>
            <div className={styles.service}>
              <div className={classnames(styles.icon, styles.vod)}></div>
              TVOD <br /> Electronic Sell Trough
            </div>

            <div className={styles.service}>
              <img
                src={rewind}
                alt="rewind logo"
                style={{
                  height: '30px',
                  margin: '10px auto 20px',
                  width: '30px',
                }}
              />
              Replay / Catchup services
            </div>

            <div className={styles.service}>
              <div className={classnames(styles.icon, styles.bulb)}></div>
              Recommendations
            </div>

            <div className={styles.service}>
              <div className={classnames(styles.icon, styles.search)}></div>
              Universal search
            </div>
          </div>
        </div>

        <div className={styles.column}>
          <div className={styles.pastille}>
            <img src={Netflix} alt="netflix" />
            <p>integration</p>
          </div>
          <img className={styles.BboxMiami} src={BboxMiami} alt="Bbox Miami" />
          <img
            className={styles.BboxLogo}
            src={BboxLogo}
            alt="Bbox Miami Logo"
          />
          <p>Launched on January 2015</p>
        </div>
      </div>
      <footer className={styles.row}>
        <div className={classnames(styles.column, styles.columnPartnership)}>
          <h2 style={{ color: '#4A4A4A' }}>In partnership with</h2>
          <div className={styles.partnership}>
            <figure>
              <img src={synaptics} alt="synaptics" />
            </figure>
            <figure>
              <img src={iwedia} alt="iwedia" />
            </figure>
            <figure>
              <img src={verimatrix} alt="verimatrix" />
            </figure>
            <figure>
              <img src={arcadyan} alt="arcadyan" />
            </figure>
            <figure>
              <img src={spideo} alt="spideo" />
            </figure>
          </div>
        </div>
      </footer>
    </section>
  )
}

export default Bbox
