import React from 'react'
import uuid from 'uuid/v4'

import widewine from './assets/player/color/Widevine.png'
import verimatrix from './assets/player/color/verimatrix.png'
import iwedia from './assets/player/color/51-iWedia.png'
import adobePrimetime from './assets/player/color/52-Adobe.png'
import visualOn from './assets/player/color/visualOn.png'
import google from './assets/player/color/55-Google.png'
import apple from './assets/player/color/56-Apple.png'

const logosArray = [
  { logo: widewine, alt: 'widewine' },
  { logo: verimatrix, alt: 'verimatrix' },
  { logo: iwedia, alt: 'iwedia' },
  { logo: adobePrimetime, alt: 'adobePrimetime' },
  { logo: visualOn, alt: 'visualOn' },
  { logo: google, alt: 'google' },
  { logo: apple, alt: 'apple' },
]

const logos = logosArray.map(logo => {
  let partnersLogo = <img src={logo.logo} alt={logo.alt} />
  if (logo.alt === 'amdocs') {
    partnersLogo = (
      <img
        src={logo.logo}
        alt={logo.alt}
        style={{ height: 'auto', width: '70%' }}
      />
    )
  }
  if (logo.alt === 'verimatrix') {
    partnersLogo = (
      <img
        src={logo.logo}
        alt={logo.alt}
        style={{ height: 'auto', width: '70%' }}
      />
    )
  }
  return <figure key={uuid()}>{partnersLogo}</figure>
})

export default logos
