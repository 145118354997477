import React from 'react'
// import ReactMardown from 'react-markdown'

import styles from './index.module.css'

import chipsetLogos from './chipsetLogos'
import hardwareLogos from './hardwareLogos'
import plateformLogos from './plateformLogos'
import softwareLogos from './softwareLogos'
import playerLogos from './playerLogos'

const Technical = ({ data }) => {
  return (
    <section className={styles.technical}>
      <h2 className={styles.title}>{data.technical_title}</h2>

      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.rowHead}>
            <h3>{data.technical_cat_title_1}</h3>
          </div>
          <div className={styles.rowBody}>
            {chipsetLogos}
            {/* <ReactMardown
              escapeHtml={false}
              source={data.technical_cat_images_1}
            /> */}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.rowHead}>
            <h3>{data.technical_cat_title_2}</h3>
          </div>
          <div className={styles.rowBody}>
            {hardwareLogos}
            {/* <ReactMardown
              escapeHtml={false}
              source={data.technical_cat_images_2}
            /> */}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.rowHead}>
            <h3>{data.technical_cat_title_3}</h3>
          </div>
          <div className={styles.rowBody}>
            {plateformLogos}
            {/* <ReactMardown
              escapeHtml={false}
              source={data.technical_cat_images_3}
            /> */}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.rowHead}>
            <h3>{data.technical_cat_title_4}</h3>
          </div>
          <div className={styles.rowBody}>
            {softwareLogos}
            {/* <ReactMardown
              escapeHtml={false}
              source={data.technical_cat_images_4}
            /> */}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.rowHead}>
            <h3>{data.technical_cat_title_5}</h3>
          </div>
          <div className={styles.rowBody}>
            {playerLogos}
            {/* <ReactMardown
              escapeHtml={false}
              source={data.technical_cat_images_5}
            /> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Technical
