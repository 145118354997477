import React from 'react'
import uuid from 'uuid/v4'

import amdocs from './assets/software/color/amdocs-black.png'
import freewheel from './assets/software/color/41-Freewheel.png'
import thinkanalytics from './assets/software/color/42-ThinkAnalytics.png'
import spideo from './assets/software/color/43-Spideo.png'
import nielsen from './assets/software/color/44-Nielsen.png'
import comscore from './assets/software/color/45-Comscore.png'

const logosArray = [
  {
    logo: amdocs,
    alt: 'amdocs',
  },
  {
    logo: freewheel,
    alt: 'freewheel',
  },
  {
    logo: thinkanalytics,
    alt: 'thinkanalytics',
  },
  {
    logo: spideo,
    alt: 'spideo',
  },
  {
    logo: nielsen,
    alt: 'nielsen',
  },
  {
    logo: comscore,
    alt: 'comscore',
  },
]

const logos = logosArray.map(logo => {
  let partnersLogo = <img src={logo.logo} alt={logo.alt} />
  if (logo.alt === 'amdocs') {
    partnersLogo = (
      <img
        src={logo.logo}
        alt={logo.alt}
        style={{ height: 'auto', width: '70%' }}
      />
    )
  }
  return <figure key={uuid()}>{partnersLogo}</figure>
})

export default logos
