import React from 'react'
import styles from './index.module.css'
import classnames from 'classnames'

import Container from '../../Container'

import { Grid, Cell } from 'styled-css-grid'
import MediaQuery from 'react-responsive'

import wow from './assets/WOW_Logo_only.png'
import screen1 from './assets/Image_WOW_360.png'
import screen2 from './assets/Image_WOW_ChannelList.png'

import talkback from './assets/icons/TalkBack.png'
import networks from './assets/icons/update - Networks.png'

import netflix from './assets/aggregation/netflix.png'
import outdoor from './assets/aggregation/Outdoor.png'
import food from './assets/aggregation/Food.png'
import paramount from './assets/aggregation/Paramount.png'
import life from './assets/aggregation/life.png'
import history from './assets/aggregation/History.png'
import adultSwim from './assets/aggregation/Adultswim.png'
import hbo from './assets/aggregation/HBO.png'
import nbc from './assets/aggregation/NBC.png'
import comedy from './assets/aggregation/comedy.png'
import cartoonNetwork from './assets/aggregation/CartoonNet.png'
import vice from './assets/aggregation/Vice.png'
import amc from './assets/aggregation/AMC.png'
import discovery from './assets/aggregation/Discovery.png'

import kaltura from './assets/partnership/kaltura.png'
import technicolor from './assets/partnership/WOW-Technicolor.png'
import broadpeak from './assets/partnership/WOW-BroadPeak.png'
import broadcom from './assets/partnership/logoBroadcom-gray.svg'

const Wow = () => {
  return (
    <section className={styles.Wow}>
      <div className={styles.triangle}></div>
      <MediaQuery minWidth={993}>
        <Container>
          <Grid columns={12}>
            <Cell width={3} middle>
              <figure className={styles.logo}>
                <img style={{ width: '300px' }} src={wow} alt="wow logo" />
              </figure>
            </Cell>
            <Cell width={9} middle center>
              <h2 className={styles.title}>
                US cable moving to Android TV eco-system
              </h2>
            </Cell>
          </Grid>
        </Container>

        <Container>
          <Grid columns={12}>
            <Cell width={7} center>
              <figure style={{ paddingTop: '2rem', maxWidth: '90%' }}>
                <img src={screen1} alt="wow 360" />
              </figure>
            </Cell>
            <Cell width={5} center>
              <Grid columns={12}>
                <Cell width={12}>
                  <h3 className={styles.aggregation}>Super Aggregation</h3>
                </Cell>
                <Cell width={4} middle>
                  <figure>
                    <img
                      style={{ margin: 0, height: '30px' }}
                      src={netflix}
                      alt="netflix logo"
                    />
                  </figure>
                </Cell>
                <Cell width={4} middle>
                  <figure>
                    <img
                      style={{ margin: 0 }}
                      src={outdoor}
                      alt="outdoor logo"
                    />
                  </figure>
                </Cell>
                <Cell width={4} middle>
                  <figure>
                    <img style={{ margin: 0 }} src={food} alt="food logo" />
                  </figure>
                </Cell>
                <Cell width={4} middle>
                  <figure>
                    <img
                      style={{ margin: 0 }}
                      src={paramount}
                      alt="paramount logo"
                    />
                  </figure>
                </Cell>
                <Cell width={4} middle>
                  <figure>
                    <img style={{ margin: 0 }} src={life} alt="life logo" />
                  </figure>
                </Cell>
                <Cell width={4} middle>
                  <figure>
                    <img
                      style={{ margin: 0 }}
                      src={history}
                      alt="history logo"
                    />
                  </figure>
                </Cell>
                <Cell width={4} middle>
                  <figure>
                    <img
                      style={{ margin: 0 }}
                      src={adultSwim}
                      alt="adultSwim logo"
                    />
                  </figure>
                </Cell>
                <Cell width={4} middle>
                  <figure>
                    <img style={{ margin: 0 }} src={hbo} alt="hbo logo" />
                  </figure>
                </Cell>
                <Cell width={4} middle>
                  <figure>
                    <img style={{ margin: 0 }} src={nbc} alt="nbc logo" />
                  </figure>
                </Cell>
                <Cell width={4} middle>
                  <figure>
                    <img style={{ margin: 0 }} src={comedy} alt="comedy logo" />
                  </figure>
                </Cell>
                <Cell width={4} middle>
                  <figure>
                    <img
                      style={{ margin: 0 }}
                      src={cartoonNetwork}
                      alt="cartoon network logo"
                    />
                  </figure>
                </Cell>
                <Cell width={4} middle>
                  <figure>
                    <img style={{ margin: 0 }} src={vice} alt="vice logo" />
                  </figure>
                </Cell>
                <Cell width={6} middle>
                  <figure>
                    <img style={{ margin: 0 }} src={amc} alt="amc logo" />
                  </figure>
                </Cell>
                <Cell width={6} middle>
                  <figure>
                    <img
                      style={{ margin: 0 }}
                      src={discovery}
                      alt="discovery logo"
                    />
                  </figure>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </Container>

        <Container>
          <Grid columns={12}>
            <Cell width={5}>
              <div className={styles.services}>
                <div className={styles.service}>
                  <div className={classnames(styles.icon, styles.live)}></div>
                  OTT Live TV Channels Kaltura Player
                </div>

                <div className={styles.service}>
                  <div className={styles.netflix}>
                    <img src={networks} alt="networks logo" />
                  </div>
                  US TV Networks content
                </div>

                <div className={styles.service}>
                  <div className={styles.netflix}>
                    <img src={talkback} alt="tallback logo" />
                  </div>
                  TalkBack
                </div>
              </div>
            </Cell>
            <Cell width={7}>
              <figure style={{ paddingTop: '2rem' }}>
                <img src={screen2} alt="wow channellist" />
              </figure>
            </Cell>
          </Grid>
          <Grid columns="repeat(5, 1fr)" justifyContent="space-around">
            <Cell middle center>
              <h3>In partnership with</h3>
            </Cell>
            <Cell middle center>
              <figure className={styles.onSmallDevices}>
                <img style={{ margin: 0 }} src={kaltura} alt="kaltura logo" />
              </figure>
            </Cell>
            <Cell middle center>
              <figure className={styles.onSmallDevices}>
                <img
                  style={{ margin: 0 }}
                  src={broadpeak}
                  alt="broadpeak logo"
                />
              </figure>
            </Cell>
            <Cell middle center>
              <figure className={styles.onSmallDevices}>
                <img
                  style={{ margin: 0 }}
                  src={technicolor}
                  alt="technicolor logo"
                />
              </figure>
            </Cell>
            <Cell middle center>
              <figure className={styles.onSmallDevices}>
                <img style={{ margin: 0 }} src={broadcom} alt="broadcom logo" />
              </figure>
            </Cell>
          </Grid>
        </Container>
      </MediaQuery>

      <MediaQuery maxWidth={992}>
        <Grid columns={12}>
          <Cell width={12} middle>
            <figure className={styles.logo}>
              <img style={{ width: '300px' }} src={wow} alt="wow logo" />
            </figure>
          </Cell>
          <Cell width={12} middle center>
            <h2 className={styles.title}>
              US cable moving to Android TV eco-system
            </h2>
          </Cell>
        </Grid>

        <Grid columns={12}>
          <Cell width={12} center>
            <Grid columns={12}>
              <Cell width={12}>
                <h3 className={styles.aggregation}>Super Aggregation</h3>
              </Cell>
              <Cell width={4} middle>
                <figure>
                  <img
                    style={{ margin: 0, height: '30px' }}
                    src={netflix}
                    alt="netflix logo"
                  />
                </figure>
              </Cell>
              <Cell width={4} middle>
                <figure>
                  <img style={{ margin: 0 }} src={outdoor} alt="outdoor logo" />
                </figure>
              </Cell>
              <Cell width={4} middle>
                <figure>
                  <img style={{ margin: 0 }} src={food} alt="food logo" />
                </figure>
              </Cell>
              <Cell width={4} middle>
                <figure>
                  <img
                    style={{ margin: 0 }}
                    src={paramount}
                    alt="paramount logo"
                  />
                </figure>
              </Cell>
              <Cell width={4} middle>
                <figure>
                  <img style={{ margin: 0 }} src={life} alt="life logo" />
                </figure>
              </Cell>
              <Cell width={4} middle>
                <figure>
                  <img style={{ margin: 0 }} src={history} alt="history logo" />
                </figure>
              </Cell>
              <Cell width={4} middle>
                <figure>
                  <img
                    style={{ margin: 0 }}
                    src={adultSwim}
                    alt="adultSwim logo"
                  />
                </figure>
              </Cell>
              <Cell width={4} middle>
                <figure>
                  <img style={{ margin: 0 }} src={hbo} alt="hbo logo" />
                </figure>
              </Cell>
              <Cell width={4} middle>
                <figure>
                  <img style={{ margin: 0 }} src={nbc} alt="nbc logo" />
                </figure>
              </Cell>
              <Cell width={4} middle>
                <figure>
                  <img style={{ margin: 0 }} src={comedy} alt="comedy logo" />
                </figure>
              </Cell>
              <Cell width={4} middle>
                <figure>
                  <img
                    style={{ margin: 0 }}
                    src={cartoonNetwork}
                    alt="cartoon network logo"
                  />
                </figure>
              </Cell>
              <Cell width={4} middle>
                <figure>
                  <img style={{ margin: 0 }} src={vice} alt="vice logo" />
                </figure>
              </Cell>
              <Cell width={6} middle>
                <figure>
                  <img style={{ margin: 0 }} src={amc} alt="amc logo" />
                </figure>
              </Cell>
              <Cell width={6} middle>
                <figure>
                  <img
                    style={{ margin: 0 }}
                    src={discovery}
                    alt="discovery logo"
                  />
                </figure>
              </Cell>
            </Grid>
          </Cell>
          <Cell width={12} center>
            <figure
              style={{ paddingTop: '2rem', maxWidth: '90%', margin: 'auto' }}
            >
              <img src={screen1} alt="wow 360" />
            </figure>
          </Cell>
        </Grid>

        <Grid columns={12}>
          <Cell width={12}>
            <div className={styles.services}>
              <div className={styles.service}>
                <div className={classnames(styles.icon, styles.live)}></div>
                OTT Live TV Channels Kaltura Player
              </div>

              <div className={styles.service}>
                <div className={styles.netflix}>
                  <img src={networks} alt="networks logo" />
                </div>
                US TV Networks content
              </div>

              <div className={styles.service}>
                <div className={styles.netflix}>
                  <img src={talkback} alt="tallback logo" />
                </div>
                TalkBack
              </div>
            </div>
          </Cell>
          <Cell width={12}>
            <figure
              style={{ paddingTop: '2rem', maxWidth: '90%', margin: 'auto' }}
            >
              <img src={screen2} alt="wow channellist" />
            </figure>
          </Cell>
        </Grid>

        <Grid columns={12}>
          <Cell width={12} center>
            <h3>In partnership with</h3>
          </Cell>
          <Cell width={12} center>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                width: '100%',
                alignItems: 'baseline',
                flexWrap: 'wrap',
              }}
            >
              <figure>
                <img style={{ margin: 0 }} src={kaltura} alt="kaltura logo" />
              </figure>
              <figure>
                <img
                  style={{ margin: 0 }}
                  src={broadpeak}
                  alt="broadpeak logo"
                />
              </figure>
              <figure>
                <img
                  style={{ margin: 0 }}
                  src={technicolor}
                  alt="technicolor logo"
                />
              </figure>
              <figure>
                <img style={{ margin: 0 }} src={broadcom} alt="broadcom logo" />
              </figure>
            </div>
          </Cell>
        </Grid>
      </MediaQuery>
    </section>
  )
}

export default Wow
